.PageBuilder {
  :global {

    .gallery-image {
      width: calc(25% - 8px);
      display: inline-block;
      vertical-align: top;
      margin: 4px;
    }

    .half .gallery-image {
      width: calc(50% - 8px);
    }

    .right .gallery-node {
      text-align: right;
    }

    .pages.builder .main {
      padding-bottom: 0;
    }

    .page-builder-container {
      position: relative;
      float: left;
      width: 100%;
    }

    .page-builder-toolbar {
      padding: 0 20px 20px 20px;

      .btn-group {
        margin-right: 8px;
      }
    }

    .page-builder-saving {
      font-size: 1em;
      display: inline-block;
      margin-left: 12px;
      margin-top: 0.2em;
    }

    .page-builder-editor {
      padding-right: 50%;
      position: relative;
      width: 100%;
    }

    .page-builder-sidebar {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      bottom: 0;
      overflow: hidden;
      overflow-y: visible;
      padding: 0 20px 40px;

      .form-footer {
        border-top: 1px solid #e5e5e5;
        padding-top: 20px;

        .btn {
          float: right;
        }
      }

      .image-present {
        position: relative;

        img {
          width: 150px;
        }

        .btn {
          position: absolute;
          top: 50%;
          left: 170px;
          transform: translateY(-50%);
        }
      }
    }

    .page-builder-items {
      height: 100%;
      overflow: hidden;
      overflow-y: visible;
      padding-left: 20px;

      &.width {
        &-90 {
          font-size: 90%;
          .text-big {
            font-size: 40px;
          }
        }
        &-80 {
          font-size: 80%;
          .text-big {
            font-size: 20px;
          }
        }
        &-70 {
          font-size: 70%;
          .text-big {
            font-size: 20px;
          }
        }
        &-60 {
          font-size: 60%;
          .text-big {
            font-size: 20px;
          }
        }
        &-50 {
          font-size: 50%;
          .text-big {
            font-size: 20px;
          }
        }
        &-40 {
          font-size: 40%;
          .text-big {
            font-size: 20px;
          }
        }
        &-30 {
          font-size: 30%;
          .text-big {
            font-size: 16px;
          }
        }
        &-20 {
          font-size: 20%;
          .text-big {
            font-size: 12px;
          }
        }
      }
    }

    .node {
      cursor: move;
      float: left;
      margin-bottom: 6px;
      padding: 6px;

      .image-node,
      .download-node {
        .title {
          position: absolute;
          left: 5px;
          top: 5px;
          color: white;
          font-weight: bolder;

          &.dark {
            color: #000;
          }


        }

        h1,
        h3,
        h2 {
          margin: 2px 0  5px;
        }
        h1 {
          font-size: 1.5em;
        }

        img {
          width: 100%;
        }
      }
    }

    .node-content {
      border: 1px dashed #aaa;
      background: #fff;
      min-height: 4em;
      position: relative;
      text-overflow: ellipsis;
      overflow-x: hidden;
      padding: 0.5rem;
    }
    .text-node {
      img {
        max-width: 100%;
        height: auto;
      }
    }

    .text-node:before {
      font: normal normal normal 14px/1 FontAwesome;
    }

    .text-node.placeholder,
    .image-node.placeholder,
    .form-node.placeholder,
    .gallery-node.placeholder,
    .download-node.placeholder {
      img {
        display: none;
      }

      &:before {
        font-size: 24px;
        opacity: 0.25;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }

    .download-node.placeholder {
      img {
        display: block;
      }

      &:before {
        content: '\f019';
        font: normal normal normal 28px/1 FontAwesome;
      }
    }

    .form-node.placeholder {
      span {
        color: teal;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        text-transform: capitalize;
        opacity: 0.7;
        padding: 0.5em;

        &:after {
          content: ' Formular';
          font-family: 'Montserrat', sans-serif;
        }
      }
    }

    .btn-select-gallery-images {
      margin-bottom: 16px;
    }

    .selected {
      .node-content {
        background: #d9edf7;
        border: 1px solid #337ab7;
        box-shadow: 0 0 3px 0 #337ab7;
      }
    }

    .isDragging {
      opacity: 0;
    }

    .page {
      background: #efefef;
      padding: 6px;
      min-height: 100%;

      &:before,
      &:after {
        content: '\0020';
        display: block;
        height: 0;
        overflow: hidden;
      }

      &:after {
        clear: both;
      }

      .node {
        box-sizing: border-box;
        display: inline-block;
        width: 100%;

        .isOver & {
          background: lightblue;
        }

        &.half {
          width: 50%;
        }

        &.quarter {
          width: 25%;
        }

        &.right {
          float: right;
        }

        &.clear {
          clear: both;
        }
      }
    }

    .download {
      text-align: center;
      width: 100px;
      border: 1px solid #ccc;
      overflow: hidden;

      .download-props {
        padding: 0 5px;

        div {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.selected {
        background: #d9edf7;
      }
    }

    .download-list .download {
      display: inline-block;
      margin: 0 1em 1em 1em;
    }

    .downloads-selected .download {
    }
  }
}
