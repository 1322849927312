.AttachmentCollection {
  .item  {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  :global {

    .preview-container {
      width: 70px;
    }

    .attachment-collection-wrap {
      background: #efefef;
      margin-bottom: 12px;
      padding: 12px;
    }

    .attachment-collection-delete {
      font-size: 2rem;
      &:hover {
        color: red
      }
    }

    .is-dragged {
       div {
        opacity: 0.2;
      }
    }
  }
}
