.AttachmentsPage {

  :global {

  }
  .drawerContent {
    padding: 1rem 1.5rem;
  }


}



