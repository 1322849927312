.attachmentList {
  // border: 4px solid red;

  :global {
    .drop-area {
      border: 1px dashed rgb(0, 136, 255);justify-content: center;
      background-color: aliceblue;
      padding: 1rem;
      border-radius: 4px;

      > .btn {
        margin-right: 1rem;
      }
    }
    .items {

      background: #FFF;
      min-height: 174px;
      overflow-y: auto;
      display: grid;
      justify-items: start;


      grid-template-columns: repeat(auto-fit, minmax(min(200px, 400px), 1fr));


      grid-gap: 1rem;

      .grid-item {
        width: 100%;

        &:hover {
          background-color: aliceblue;
        }
      }
    }



    .attachments-toolbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap-reverse;

      > div:is(:first-child) {
        flex-grow: 1;
        margin-right: 1rem;
      }

      .attachments-toolbar-search {
        flex-grow: 0;

        label {
          display: inline-block;
          margin-right: 4px;
        }

        [name="file_or_title_de_cont"] {
          display: inline-block;
          width: auto;
        }

        .form-group {
          white-space: nowrap;
          width: auto;
        }
      }
    }

    .dropable-list {
      margin-top: 10px;
    }

    .drop-area {
      display: flex;
      align-items: center;
      // justify-content: start;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }


  .footer {
    position: fixed;
    width: 100%;
    bottom: -10px;
    // margin-left: -24px;
    padding-left: 24px;
    background: #ffffff7d;
    backdrop-filter: blur(7px);

    &.inModal {
      left: 0;
      width: auto;
      background: transparent;
      backdrop-filter: unset;
      border: unset;
      margin-left: 0;
      bottom: 0;

      :global {
        .pagination {
          margin: 0;
        }
      }
    }
  }

  .footer.in-modal{
    bottom: 40px;
    background-color: pink;
  }

}

