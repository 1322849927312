.AttachmentForm {
  .previewRow {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed rgb(0, 136, 255);justify-content: center;
    background-color: aliceblue;
    padding: 1rem;
    border-radius: 4px;

    > div {
      flex: 1;
      margin: 0 10px;
    }
  }
}
