.Download{


  border-radius: 4px;
  cursor: pointer;

  img {
    background: #efefef;;
  }

}
