.AttachmentPreview {
  background: #eee;
  position: relative;
  overflow: hidden;
  width: 100%;

  :global {

    .preview {
        // width: 150px;
        // min-height: 150px;
    }
    .icon {
      margin: 10px 0 0 10px;
      color: #9999AA;
      font-size: 3em;
    }

    .preview-icon {
      // display: inline;
      z-index: 100;
      position: absolute;
      size: 3em;
      top: 0px;
      left: 0;
    }

    .image-wrap {
      position: relative;
      width: 100%;
      overflow: hidden;
      padding-bottom: 100%;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    a {
      cursor: pointer;
    }

    .progressBar {
      background: #FF00FF;
      position: absolute;
      height: 5px;
      width: 15px;
      left: 0;
      bottom: 0;
    }
  }
}
