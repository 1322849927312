.DownloadList{

}
.footer {
  width: unset;
  left: 0;
  background: unset;
  backdrop-filter: unset;
  border: none;
}
