.AttachmentsChooser {

  :global {
    .attachmentSelected {
      width: 70px;
      float: left;
      margin: 0 2px 2px 0;
    }

    .col-md-3 {
      position: fixed;
      right: 0;
    }
  }
}
